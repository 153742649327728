import App from './App.vue';
import { createApp } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';
import router from './router.js';
import store from './store.js';



const app = createApp(App)

app.use(router)
app.use(store)
app.use(
  createAuth0({
    domain: "dev-ay6kocp1raulleq7.us.auth0.com",
    clientId: "PQ4j0ve7eFliu7yNOOYWGVuSJjV99jpg",
    authorizationParams: {
      redirect_uri: window.location.origin
    }
  })
);

app.mount('#app');
