<template>
    <TabsComponent v-show="tabs.length" />
    <div class="tab__content">
        <ResultComponent 
            class="tab__content-item"
            :data="resultData"
            v-show="isReady && isExist"/>
    </div>
</template>

<style>
</style>

<script>
import { computed, ref, watch, onMounted } from 'vue';
import ResultComponent from '@/components/ResultComponent/ResultComponent.vue';
import store from '@/store';
import TabsComponent from '@/components/TabsComponent/TabsComponent.vue';
import { useRoute } from 'vue-router';

export default {
    components: {
        ResultComponent,
        TabsComponent
    },

    setup() {
        const isExist = ref(false);
        const isReady = ref(false);
        const tabs = computed(() => store.getters.GET_TABS_LIST);
        const tabsContent = computed(() => store.getters.GET_TABS_DATA);
        const activeTab = computed(() => store.getters.GET_ACTIVE_TAB);
        const resultData = ref({});
        const route = useRoute();

        onMounted(() => {
            store.commit('SET_ACTIVE_TAB', route.params.id ? route.params.id : 'taboola');
            checkExists();
        });

        const checkExists = () => {
            const allTabsKeys = Object.keys(tabsContent.value);
            isExist.value = (allTabsKeys.includes(activeTab.value));

            setResultData();
        }

        const setResultData = () => {
            if (!isExist.value) {
                return;
            }

            resultData.value.title = isExist.value ? null : 'Данные текущего таба отсутствуют';
            resultData.value.content = isExist.value ? tabsContent.value[activeTab.value] : 'Пожалуйста выберите другой таб';
            resultData.value.empty = !isExist.value;

            isReady.value = true;
        }

        watch(
            [tabsContent, activeTab, () => {
                checkExists();
            }]
        );

        return {
            activeTab,
            isExist,
            isReady,
            ResultComponent,
            resultData,
            tabs,
            tabsContent
        }
    }
}
</script>