import { createRouter, createWebHistory } from 'vue-router';
// import { authGuard } from "@auth0/auth0-vue";
import MainPage from './pages/MainPage';
// import store from '@/store';

const router = new createRouter({
    history: createWebHistory(),
    routes : [
        {
            path: '/',
            name: 'main',
            component: MainPage,
            // beforeEnter: authGuard
        }, {
            path: '/:id',
            component: MainPage,
            props: true,
            // beforeEnter: authGuard
            // beforeEnter: (to) => {
            //     store.commit('SET_ACTIVE_TAB', to.params.id);
            // },
            // meta: {
            //     requiresAuth: true
            // }
        }
    ]
});
 


// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (getAuth().currentUser) {
//             next();
//         } else {
//             alert('Вы не авторизованы, пройдите авторизацию');
//             next('/sign-in');
//         }
//     } else {
//         next();
//     }
// })

export default router;