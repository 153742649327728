import axios from 'axios';
import { createStore } from 'vuex';

const state = {
    tabs      : [],
    tabsData  : {},
    activeTab : 'taboola',
    defaultTab: '',
    afterAuth : true,
    dataLoaded: false
};

const getters = {
    /**
     * Возвращает список табов
     * @param {object} state - объект хранищища
     * @returns {array} - список табов
     */
    GET_TABS_LIST(state) {
        return state.tabs;
    },

    /**
     * Возвращает объект с данными всех табов
     * @param {object} state - объект хранищища
     * @returns {object} - объект с данными всех табов
     */
    GET_TABS_DATA(state) {
        return state.tabsData
    },

    /**
     * Возвращает актиынй таб
     * @param {object} state - объект хранищища
     * @returns {string} - активный таб
     */
    GET_ACTIVE_TAB(state) {
        return state.activeTab;
    },

    GET_AFTER_AUTH(state) {
        return state.afterAuth;
    }
};

const actions = {
    /**
     * Отравляет запрос на получение данных и запись их в store
     * @param {*} param0 
     */
    getAllData({ commit }) {
        axios.get("/source/tabs_data.json")
            .then((response) => {
                commit('SET_TABS_LIST', response.data.tabs);
                commit('SET_TABS_DATA', response.data.tabsContent);
                response.data.active && commit('SET_DEFAULT_TAB', response.data.active);
            });
    },
};

const mutations = {
    /**
     * Записывает в store все активные табы
     * @param {object} state - объект хранищища
     * @param {array} data - массив табов
     */
    SET_TABS_LIST(state, data) {
        state.tabs = data;
    },

    /**
     * Записывает в store все данные табов
     * @param {object} state - объект хранилища
     * @param {object} data - объект с данными табов
     */
    SET_TABS_DATA(state, data) {
        state.tabsData = data;
    },

    /**
     * Записывает в store id активного таба
     * @param {object} state - объект хранилища
     * @param {string} data - id активного таба
     */
    SET_ACTIVE_TAB(state, data) {
        state.activeTab = data;
    },

    /**
     * Записывает в store id таба открывающегося по умолчанию 
     * @param {object} state - объект хранилища
     * @param {string} data - значение таба по умолчанию 
     */
    SET_DEFAULT_TAB(state, data) {
        state.defaultTab = data;
    },

    SET_AFTER_AUTH(state, data) {
        state.afterAuth = data;
    }
};

export default createStore({
    state,
    getters,
    actions,
    mutations
});