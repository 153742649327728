<template>
    <div class="tabs__wrapper" v-if="tabs.length">
        <div class="tabs">
            <router-link 
                :class="['tabs__item', {'is-active': tab.id === activeTab}]"
                v-for="tab in tabs"
                :id=tab.id
                :to=tab.id
                :key=tab.id
                @click="changeActiveTab(tab.id)">
                {{ tab.name }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';

export default {

    setup() {
        const store = useStore();

        const tabs = computed(() => store.getters.GET_TABS_LIST);
        const activeTab = computed(() => store.getters.GET_ACTIVE_TAB);

        const changeActiveTab = (id) => {
            store.commit('SET_ACTIVE_TAB', id);
        };

        // Подскролл до активного таба, если он вне зоны видимости 
        const scrollToActive = () => {
            if (!activeTab.value || !tabs.value) {
                return;
            }
            
            const activeTabElement = document.getElementById(activeTab.value);
            
            if (activeTabElement) {
                setTimeout(() => {
                    activeTabElement.scrollIntoView({behavior: "smooth", inline: "end"});
                }, 300);
            }
        };


        onMounted(() => scrollToActive());

        onUpdated(() => scrollToActive());

        return {
            tabs,
            activeTab,
            changeActiveTab
        }
    }
}
</script>

<style scoped>
.tabs__wrapper {
    margin: 50px;
}

.tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    scrollbar-width: thin;          /* "auto" или "thin"  */
    scrollbar-color: #64B5F6 #FAFAFA;   /* плашка скролла и дорожка */
    overflow-X: scroll;
}

.tabs::-webkit-scrollbar {
    width: 8px;               /* ширина scrollbar */
}
.tabs::-webkit-scrollbar-track {
    background: #FAFAFA;        /* цвет дорожки */
}

.tabs::-webkit-scrollbar-thumb {
    background-color: #64B5F6;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 3px solid #FAFAFA;  /* padding вокруг плашки */
}

.tabs__item {
    border: none;
    margin: 0;
    padding: 10px 16px;
    width: auto;
    overflow: visible;
    text-align: inherit;
    outline: none;
    cursor: pointer;
    background-color: #1976d2;
    border-radius: 10px;
    color: #ffffff;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    cursor: pointer;
    flex: none;
    text-decoration: none;
}

.tabs__item.is-active {
    background-color: #43a047;
}

.tabs__item:not(:first-child) {
    margin-left: 20px;
}
</style>