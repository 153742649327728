<template>
    <div 
        :class="['result', {'result_theme_empty': data.empty}]" v-if="data">
        <div class="result__title" v-if="data.title">{{ data.title }}</div>
        <div class="result__content">
            <div class="result__content-data" v-if="!data.empty">
                <div 
                    class="result__content-item"
                    v-for="(item, index) in data.content?.code"
                    :key="index"
                    v-html="item"
                ></div>
            </div>
            <div class="result__content-empty" v-else v-html="data.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    }
}
</script>

<style>
.result {
    font-family: 'Montserrat';
}

.result__title {
    margin: 25px 0;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.result__content-empty {
    font-size: 22px;
    text-align: center;
}

.result_theme_empty {
    height: calc(100vh - 174px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result__content {
    height: calc(100vh - 174px);
    min-height: 600px;
}

.result__content-data,
.result__content-item {
    height: 100%;
}
</style>