<template>
  <RouterView />
</template>

<script>
import { RouterView } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    RouterView
  },

  setup() {
    const store = useStore();

    store.dispatch("getAllData");
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600&display=swap');
</style>
